import { createRouter, createWebHistory } from 'vue-router'
import WordContainer from '&/WordContainer.vue'
import CategoryIndex from '&/CategoryIndex.vue'
import CategoryShow from '&/CategoryShow.vue'
import WordShow from '&/WordShow.vue'
import SearchResult from '&/SearchResult.vue'
import ArticleIndex from '&/ArticleIndex.vue'
import ArticleShow from '&/ArticleShow.vue'
import AppAbout from '&/AppAbout.vue'
import FeedbackNew from '&/FeedbackNew.vue'

const routes = [
    {
        path: '/',
        name: 'WordContainer',
        component: WordContainer,
        children: [
            {
                path: '/',
                name: 'CategoryIndex',
                component: CategoryIndex
            },
            {
                path: '/tu-vung-theo-chu-de/:slug',
                name: 'CategoryShow',
                component: CategoryShow,
                props: (route) => ({ slug: route.params.slug })
            },
            {
                path: '/tim-kiem/:keyword',
                name: 'SearchResult',
                component: SearchResult
            },
            {
                path: '/tu-vung/:category_slug/:word',
                name: 'WordShow',
                component: WordShow,
                props: (route) => ({ category_slug: route.params.category_slug, word: route.params.word })
            },
            {
                path: '/bai-viet',
                name: 'ArticleIndex',
                component: ArticleIndex,
            },
            {
                path: '/bai-viet/:slug',
                name: 'ArticleShow',
                component: ArticleShow,
                props: (route) => ({ slug: route.params.slug })
            }
        ]
    },
    {
        path: '/thong-tin',
        name: 'About',
        component: AppAbout
    },
    {
        path: '/phan-hoi',
        name: 'Feedback',
        component: FeedbackNew
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
