<template>
  <div class="fb-comments" :data-href="url" data-width="100%" data-numposts="5" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    url: {
      type: String,
      required: true
    }
  }
})
</script>
