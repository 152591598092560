<template>
  <div class="bg-white p-2 lg:p-4">
    <div class="my-4">
      <AdsenseMain2 />
    </div>

    <div v-if="ready">
      <AppModal id="wordDetailModal">
        <template #header>
          {{ $t('dictionary.detail') }}
        </template>
        <WordDetail :word="wordDetail" />
      </AppModal>

      <h1 class="text-2xl text-primary">
        <i class="fas fa-search" />
        {{ $t('search.result', { count: page.totalCount, keyword }) }}
      </h1>

      <table v-if="words" class="mx-auto search-result-table">
        <SearchResultItem v-for="word in words" :key="word.id" :word="word" @view-detail="viewDetail" />
      </table>

      <AppPagination class="mx-auto mt-4 w-max" @change-page="changePage" />
    </div>

    <div v-else>
      <h1 class="text-2xl text-primary">
        <i class="fas fa-search" />
        Searching '{{ keyword }}'...
      </h1>
    </div>

    <div class="my-4">
      <AdsenseMultiplex />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useWordStore } from '%/stores/wordStore'
import AppPagination from '&/AppPagination.vue'
import SearchResultItem from '&/SearchResultItem.vue'
import AdsenseMain2 from '&/AdsenseMain2.vue'
import AdsenseMultiplex from '&/AdsenseMultiplex.vue'
import AppModal from '&/AppModal.vue'
import WordDetail from '&/WordDetail.vue'

const route = useRoute()
const router = useRouter()
const wordStore = useWordStore()
const words = computed(() => wordStore.searchedWords)
const page = computed(() => wordStore.page)
const keyword = computed(() => route.params.keyword)
const ready = ref(false)

onBeforeMount(() => {
  wordStore.page.currentPage = parseInt(<string>route.query.page) || 1
})

onMounted(() => {
  window.scrollTo(0, 0)
  wordStore
    .search(keyword.value)
    .then(() => {
      document.title = `Nghĩa của từ ${keyword.value} | Tiếng Nhật Chuyên Ngành IT`
    })
    .finally(() => {
      ready.value = true
    })
})

const changePage = function (page) {
  wordStore.page.currentPage = page
  router.push({ name: 'SearchResult', params: { keyword: keyword.value }, query: { page: page } })
}

const wordDetail = ref({})

const viewDetail = function (wordId) {
  wordDetail.value = wordStore.searchedWords.find((i) => i.id === wordId)
  window.openModal('wordDetailModal')
}
</script>
