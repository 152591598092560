<template>
  <div class="p-2 lg:p-4">
    <h1 class="page-title">Tiếng Nhật công nghệ thông tin</h1>

    <div class="my-4">
      <AdsenseMain2 />
    </div>

    <AppLoading v-if="articleStore.dataLoading" />

    <div v-else>
      <div class="flex flex-col space-y-6 article-list">
        <ArticleListItem v-for="article in articleStore.articles" :key="article.id" :article="article" />
      </div>
    </div>

    <div class="my-4">
      <AdsenseMultiplex />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useArticleStore } from '%/stores/articleStore'
import ArticleListItem from '&/ArticleListItem.vue'
import AppLoading from './AppLoading.vue'
import AdsenseMain2 from '&/AdsenseMain2.vue'
import AdsenseMultiplex from '&/AdsenseMultiplex.vue'

const articleStore = useArticleStore()

onMounted(() => {
  document.title = `Tiếng Nhật Chuyên Ngành Công Nghệ Thông Tin | tiengnhatit.com`
  document.querySelector('meta[name="description"]').content =
    'Học từ vựng chuyên ngành IT tiếng tiếng Nhật, tiếng Anh. Từ vựng tiếng Nhật chuyên ngành IT thường dùng dành cho kỹ sư phần mềm, kỹ sư cầu nối.'
})
</script>
