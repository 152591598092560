<template>
  <div
    :id="id"
    class="modal fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4 hidden shadow-2xl"
    aria-hidden="true"
    aria-labelledby=""
    tabindex="-1"
  >
    <div :class="`modal-dialog modal-dialog-centered modal-dialog-scrollable ${size}`">
      <div class="modal-content">
        <div v-if="hasHeader" class="modal-header bg-primary text-white font-bold">
          <h3>
            <slot name="header" />
          </h3>
          <button @click="closeModal">
            <i class="fas fa-times-circle" />
          </button>
        </div>

        <div class="modal-body bg-white max-h-[60vh]">
          <slot />
        </div>

        <div class="modal-footer bg-gray-100">
          <div class="btn btn--primary shadow-md" @click="closeModal">
            <i class="fas fa-times" />
            {{ $t('dictionary.close') }} (ESC)
          </div>
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: ''
    }
  },

  setup(props, { slots }) {
    const closeModal = function () {
      window.closeModal(props.id)
    }
    const hasHeader = computed(() => slots.header)

    return { closeModal, hasHeader }
  }
})
</script>

<style lang="sass" scoped>
::-webkit-scrollbar
  -webkit-appearance: none
  width: 7px

::-webkit-scrollbar-thumb
  border-radius: 4px
  background-color: rgba(0, 0, 0, 0.5)
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5)
</style>
