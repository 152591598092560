<template>
  <div class="inline-block cursor-pointer ml-2 text-gray-500 hover:text-primary" @click="speak">
    <i class="fas fa-volume-down" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true
    },
    lang: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const speak = function () {
      let lang = null
      switch (props.lang) {
        case 'ja':
          lang = 'Japanese Female'
          break
        case 'en':
          lang = 'UK English Male'
          break
        case 'vi':
          lang = 'Vietnamese Male'
      }
      responsiveVoice.speak(props.text, lang) // eslint-disable-line
    }

    return { speak }
  }
})
</script>
