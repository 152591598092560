<template>
  <form @submit.prevent>
    <input
      ref="inputField"
      v-model="keyword"
      type="search"
      :placeholder="$t('search.placeholder')"
      class="input-field search-field"
      @keydown="handleKeydown"
    />

    <div class="relative search-dropdown">
      <div
        v-show="showDropdown"
        class="absolute top-0 right-0 z-50 bg-white text-black shadow-xl rounded-lg w-full lg:w-[500px] max-h-[60vh] overflow-y-scroll"
      >
        <router-link
          v-for="(word, index) in searchedWords"
          :key="index"
          :to="{ path: `/tu-vung/${word.category_slug}/${word.ja || word.en}` }"
          class="flex space-x-2 justify-between p-2 border-b border-solid border-zinc-200"
          :class="{ 'selected-item': index === selectedIndex }"
          @click="showDropdown = false"
        >
          <div>
            <strong>{{ word.ja }}</strong>
            <span class="ml-4">{{ word.ja_kana }}</span>
            <div>{{ word.en }}</div>
            <em>{{ word.vi }}</em>
          </div>
          <div class="max-w-[80px] lg:max-w-[120px]">
            <WordPreview :word="word" />
          </div>
        </router-link>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import { useWordStore } from '%/stores/wordStore'
import WordPreview from '&/WordPreview.vue'

const router = useRouter()
const wordStore = useWordStore()
const keyword = ref(null)
const inputField = ref(null)
const selectedIndex = ref(-1)

const showDropdown = ref(false)
const searchedWords = computed(() => {
  if (keyword.value) {
    return wordStore.words.filter((word) => {
      return (
        (word.ja || '').indexOf(keyword.value.toLowerCase()) !== -1 ||
        (word.ja_kana || '').indexOf(keyword.value.toLowerCase()) !== -1 ||
        (word.ja_romaji || '').toLowerCase().indexOf(keyword.value.toLowerCase()) !== -1 ||
        (word.en || '').toLowerCase().indexOf(keyword.value.toLowerCase()) !== -1 ||
        (word.vi || '').toLowerCase().indexOf(keyword.value.toLowerCase()) !== -1 ||
        (word.preview || '').toLowerCase().indexOf(keyword.value.toLowerCase()) !== -1
      )
    })
  }
})

const handleKeydown = (event) => {
  switch (event.key) {
    case 'ArrowDown':
      if (selectedIndex.value < searchedWords.value.length - 1) {
        selectedIndex.value++
      }
      break
    case 'ArrowUp':
      if (selectedIndex.value > 0) {
        selectedIndex.value--
      }
      break
    case 'Enter':
      if (selectedIndex.value >= 0) {
        const word = searchedWords.value[selectedIndex.value]
        router.push({ path: `/tu-vung/${word.category_slug}/${word.ja || word.en}` })
      } else {
        router.push({ name: 'SearchResult', params: { keyword: keyword.value } })
      }
      showDropdown.value = false
      selectedIndex.value = -1
      inputField.value.select()
      break
  }
}

const onClickOutside = (event) => {
  if (!event.target.closest('.search-field') && !event.target.closest('.search-dropdown')) {
    showDropdown.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', onClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', onClickOutside)
})

watch(keyword, (newKeyword) => {
  if (newKeyword && newKeyword !== ' ') {
    showDropdown.value = true
  } else {
    showDropdown.value = false
  }
})
</script>

<style scoped lang="sass">
.selected-item
  background-color: #ddd
</style>
