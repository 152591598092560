<template>
  <label :for="id">
    {{ label }}
    <abbr v-if="required" title="Required" class="text-red-500 ml-1 no-underline">(*)</abbr>
  </label>
  <small v-if="error" class="text-red-500 float-right"><i class="fas fa-exclamation-triangle" /> {{ error }}</small>

  <input
    v-if="['text', 'email', 'search'].includes($attrs.type)"
    :id="id"
    :placeholder="placeholder"
    :value="modelValue"
    v-bind="$attrs"
    :class="['input-field', { 'input-field--error': error }]"
    @input="$emit('update:modelValue', $event.target.value)"
  />

  <textarea
    v-else
    :id="id"
    :placeholder="placeholder"
    :value="modelValue"
    v-bind="$attrs"
    :class="['input-field', { 'input-field--error': error }]"
    rows="5"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    }
  },
  emits: ['update:modelValue'],

  setup() {
    const id = computed(() => {
      return Math.random().toString(36).substr(2, 8)
    })

    return { id }
  }
})
</script>
