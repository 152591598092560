<template>
  <div v-if="ready">
    <article class="article my-4">
      <AdsenseMain1 class="mb-4" />

      <h1 class="article__title">
        {{ articleStore.article.title }}
      </h1>
      <div class="article__info">
        <div class="flex justify-between">
          <div></div>
          <div>{{ $t('article.updatedAt') }}: {{ articleStore.article.updated_at }}</div>
        </div>
      </div>
      <div class="trix-content wysiwyg" v-html="articleStore.article.content" />
    </article>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useArticleStore } from '%/stores/articleStore'
import AdsenseMain1 from '&/AdsenseMain1.vue'

const props = defineProps<{
  slug: string
}>()

const articleStore = useArticleStore()

const ready = ref(false)

onMounted(() => {
  window.scrollTo(0, 0)
  articleStore
    .fetchArticle(props.slug)
    .then(() => {
      document.title = `${articleStore.article.title} | Tiếng Nhật Chuyên Ngành IT`
      document.querySelector('meta[name="description"]').content = articleStore.article.description
      renderImages()
    })
    .finally(() => {
      ready.value = true
    })
})

const renderImages = function () {
  setTimeout(() => {
    const attachments = document.querySelectorAll(
      'action-text-attachment[content-type="image/jpeg"], ' +
        'action-text-attachment[content-type="image/png"], ' +
        'action-text-attachment[content-type="image/gif"]'
    )
    const hrTags = document.querySelectorAll(
      'action-text-attachment[content-type="vnd.rubyonrails.horizontal-rule.html"]'
    )
    for (const attachment of attachments) {
      const img = document.createElement('img')
      img.setAttribute('src', attachment.getAttribute('url'))
      img.setAttribute('alt', attachment.getAttribute('filename'))
      img.setAttribute('title', attachment.getAttribute('filename'))
      attachment.parentNode.replaceChild(img, attachment)
    }
    for (const hr of hrTags) {
      hr.parentNode.replaceChild(document.createElement('hr'), hr)
    }
  }, 1000)
}
</script>
