<template>
  <div class="hint p-4 rounded-md bg-sky-100 text-gray-700 italic">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({})
</script>

<style lang="sass">
.hint
  position: relative
  &::before
    position: absolute
    content: ''
    background: url('../../assets/images/icons/hint.svg') no-repeat
    background-size: 48px 48px
    width: 48px
    height: 48px
    top: -1rem
    right: -1rem
</style>
