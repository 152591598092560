<template>
  <div class="my-4">
    <AdsenseMain1 />
  </div>

  <AppLoading v-if="loading" />

  <div v-else class="category-show bg-white p-2 lg:p-4">
    <AppModal id="wordDetailModal">
      <template #header>
        {{ $t('dictionary.detail') }}
      </template>
      <WordDetail :word="wordDetail" />
    </AppModal>

    <h1 class="category-title">
      {{ wordStore.category['name_' + $i18n.locale] }}
    </h1>

    <div v-if="wordStore.category.layout_name === 'Image'" class="grid grid-cols-12 gap-4 lg:gap-6">
      <WordLayoutImage
        v-for="word in wordStore.category.words"
        :key="word.id"
        :categorySlug="wordStore.category.slug"
        :word="word"
        @view-detail="viewDetail"
      />
    </div>

    <div v-else-if="wordStore.category.layout_name === 'Color'" class="grid grid-cols-12 gap-4 lg:gap-6">
      <WordLayoutColor
        v-for="word in wordStore.category.words"
        :key="word.id"
        :categorySlug="wordStore.category.slug"
        :word="word"
      />
    </div>

    <div v-else-if="wordStore.category.layout_name === 'Character'" class="grid grid-cols-12 gap-4 lg:gap-6">
      <WordLayoutCharacter
        v-for="word in wordStore.category.words"
        :key="word.id"
        :categorySlug="wordStore.category.slug"
        :word="word"
      />
    </div>

    <div v-else-if="wordStore.category.layout_name === 'Name'" class="grid grid-cols-12 gap-4 lg:gap-6">
      <WordLayoutName
        v-for="word in wordStore.category.words"
        :key="word.id"
        :categorySlug="wordStore.category.slug"
        :word="word"
        @view-detail="viewDetail"
      />
    </div>

    <table v-else-if="wordStore.category.layout_name === 'Computerese'" class="w-full">
      <WordLayoutComputerese
        v-for="word in wordStore.category.words"
        :key="word.id"
        :categorySlug="wordStore.category.slug"
        :word="word"
      />
    </table>

    <table v-else>
      <WordLayoutNormal
        v-for="word in wordStore.category.words"
        :key="word.id"
        :categorySlug="wordStore.category.slug"
        :word="word"
        @view-detail="viewDetail"
      />
    </table>

    <AppPagination class="mx-auto mt-4 w-max" @change-page="changePage" />
  </div>

  <div class="mt-4">
    <AdsenseMain1 />
  </div>

  <div class="my-8 p-4 bg-blue-50 rounded-md">
    <div class="">{{ $t('comment.ifYouHaveAnyWord') }} 😊</div>
    <CategoryFacebookComment :url="currentUrl" />
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref, watch } from 'vue'
import { useWordStore } from '%/stores/wordStore'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import AppPagination from '&/AppPagination.vue'
import AppModal from '&/AppModal.vue'
import WordLayoutImage from '&/WordLayoutImage.vue'
import WordLayoutColor from '&/WordLayoutColor.vue'
import WordLayoutCharacter from '&/WordLayoutCharacter.vue'
import WordLayoutName from '&/WordLayoutName.vue'
import WordLayoutComputerese from '&/WordLayoutComputerese.vue'
import WordLayoutNormal from '&/WordLayoutNormal.vue'
import WordDetail from '&/WordDetail.vue'
import CategoryFacebookComment from '&/CategoryFacebookComment.vue'
import AdsenseMain1 from '&/AdsenseMain1.vue'

const props = defineProps<{
  slug: string
}>()

const wordStore = useWordStore()
const route = useRoute()
const router = useRouter()
const i18n = useI18n()
const currentUrl = computed(() => `${window.location.origin}${window.location.pathname}`)

const loading = ref(true)

onBeforeMount(() => {
  wordStore.page.currentPage = parseInt(<string>route.query.page) || 1
})

onMounted(() => {
  initFB().catch((err) => console.warn(err))
  window.scrollTo(0, 0)

  wordStore
    .fetchCategory(props.slug)
    .then(() => {
      document.title = `Từ vựng tiếng Nhật về ${wordStore.category.name_vi} | Tiếng Nhật chuyên ngành IT`
      document.querySelector('meta[name="description"]').content =
        `Học tiếng Nhật chủ đề ${wordStore.category.name_vi}. Tổng hợp từ vựng tiếng Nhật chuyên ngành IT. Tiếng Nhật chuyên ngành IT thường dùng trong công việc. | Học tiếng Nhật IT online`
    })
    .finally(() => (loading.value = false))
})

const changePage = function (page) {
  wordStore.page.currentPage = page
  router.push({ name: 'CategoryShow', query: { page: page } })
}

const initFB = async function () {
  await fbAsyncInit() // eslint-disable-line
}

const wordDetail = ref({})

const viewDetail = function (wordId) {
  wordDetail.value = wordStore.category.words.find((i) => i.id === wordId)
  window.openModal('wordDetailModal')
}
</script>
