<template>
  <tr class="word word--layout-computerese">
    <td class="w-1/3">
      <div v-if="word.preview">
        <router-link :to="{ path: `/tu-vung/${categorySlug}/${word.ja || word.en}` }" class="hover:underline">
          <span class="word__en">{{ word.preview }}</span>
        </router-link>
        <WordSpeak :text="word.preview" lang="en" />
      </div>
    </td>

    <td>
      <div v-if="word.en">
        <span class="">{{ word.en }}</span>
        <WordSpeak :text="word.en" lang="en" />
      </div>
      <div v-if="word.vi">
        <span class="">{{ word.vi }}</span>
        <WordSpeak :text="word.vi" lang="vi" />
      </div>
      <div v-if="word.ja">
        <span class="">{{ word.ja }}</span>
        <span v-if="word.ja_kana">
          <span class="word__ja-kana">{{ word.ja_kana }}</span>
        </span>
        <WordSpeak :text="word.ja" lang="ja" />
      </div>
    </td>

    <td>
      <div v-if="word.image_attached || word.description_vi" class="link inline-block w-max" @click="toggleDetail">
        <div v-if="showDetail">
          <i class="fas fa-caret-down" />
          Hide
        </div>
        <div v-else>
          <i class="fas fa-caret-right" />
          Detail
        </div>
      </div>
    </td>
  </tr>

  <tr v-show="showDetail" class="word word--layout-computerese">
    <td colspan="3">
      <img v-if="word.image_attached" :src="word.image" :alt="word.preview" class="w-1/3 float-left mr-4" />
      <div v-html="word.description_vi" />
    </td>
  </tr>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import WordSpeak from '&/WordSpeak.vue'

defineProps<{
  categorySlug: string
  word: object
}>()

const showDetail = ref(false)

const toggleDetail = function () {
  showDetail.value = !showDetail.value
}
</script>
