<template>
  <ins
    class="adsbygoogle"
    :data-ad-layout="adLayout"
    :data-ad-client="adClient"
    :data-ad-slot="adSlot"
    :data-ad-format="adFormat"
    :data-ad-layout-key="adLayoutKey"
    :data-full-width-responsive="adResponsive"
    :style="adStyle"
    :class="adClass"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  props: {
    adLayout: {
      type: String,
      default: ''
    },
    adClient: {
      type: String,
      default: ''
    },
    adSlot: {
      type: String,
      default: ''
    },
    adFormat: {
      type: String,
      default: ''
    },
    adLayoutKey: {
      type: String,
      default: ''
    },
    adResponsive: {
      type: String,
      default: ''
    },
    adStyle: {
      type: String,
      default: ''
    },
    adClass: {
      type: String,
      default: ''
    }
  },

  setup() {
    onMounted(() => {
      ;(window.adsbygoogle = window.adsbygoogle || []).push({})
    })
  }
})
</script>

<style lang="sass" scoped>
.google-ad--header
  width: 180px
  height: 128px
.google-ad--footer
  width: 320px
  height: 128px
  margin: 0.25rem auto
.google-ad--top
  height: 128px
.google-ad--aside
  height: 300px

@media(min-width: 500px)
  .google-ad--header
    width: 256px
    height: 128px
  .google-ad--footer
    width: 512px
    height: 128px

@media(min-width: 800px)
  .google-ad--header
    width: 1152px
    height: 128px
  .google-ad--footer
    width: 1152px
    height: 140px
</style>
