<template>
  <div class="p-2 lg:p-4 bg-white">
    <img :src="imgIntro" alt="tiengnhatit.com" class="w-full lg:w-3/4 mx-auto" />

    <div class="my-4">
      <AdsenseMain2 />
    </div>

    <AppLoading v-if="wordStore.dataLoading" />

    <div v-else>
      <div class="text-center text-2xl mt-2 text-green-700">
        <span>{{ $t('count.category', wordStore.categoryCount) }}</span>
        &
        <span>{{ $t('count.word', wordStore.wordCount) }}</span>
      </div>

      <div class="mt-4 grid grid-cols-12 gap-6 category-list">
        <CategoryListItem
          v-for="category in wordStore.categories"
          :key="category.id"
          :category="category"
          class="col-span-12 md:col-span-6 lg:col-span-4"
        />
      </div>
    </div>

    <div class="my-4">
      <AdsenseMultiplex />
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, toRefs, computed, onMounted } from 'vue'
import { useWordStore } from '%/stores/wordStore'
import CategoryListItem from '&/CategoryListItem.vue'
import AppLoading from './AppLoading.vue'
import AdsenseMain2 from '&/AdsenseMain2.vue'
import AdsenseMultiplex from '&/AdsenseMultiplex.vue'
import imgIntro from '../../assets/images/intro.jpg'

const wordStore = useWordStore()
const data = reactive({
  imgIntro
})

onMounted(() => {
  document.title = `Từ Vựng Tiếng Nhật Chuyên Ngành Công Nghệ Thông Tin | tiengnhatit.com`
  document.querySelector('meta[name="description"]').content =
    'Học từ vựng chuyên ngành IT tiếng tiếng Nhật, tiếng Anh. Từ vựng tiếng Nhật chuyên ngành IT thường dùng dành cho kỹ sư phần mềm, kỹ sư cầu nối.'
})
</script>
