import axios from 'axios'

const apiCall = axios.create({
    baseURL: '/api/v1',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

export default apiCall
