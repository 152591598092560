<template>
  <div
    v-if="GStore.flashMessage.content"
    class="flash-message fixed z-40 top-16 right-8 w-80 p-4 rounded bg-green-300 text-green-900"
    :class="{ alert: GStore.flashMessage.type === 'alert' }"
  >
    <div>{{ GStore.flashMessage.content }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'

export default defineComponent({
  setup() {
    const GStore = inject('GStore')

    return { GStore }
  }
})
</script>

<style lang="sass" scoped>
.flash-message
  animation: fadeOut 8s
  animation-fill-mode: both
@keyframes fadeOut
  0%
    opacity: 1
  75%
    opacity: 1
  100%
    opacity: 0
.alert
  background-color: #ee827c
  color: #730E15
</style>
