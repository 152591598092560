<template>
  <form @submit.prevent="submit">
    <div>
      <AppInput
        v-model="name"
        name="name"
        label="Name"
        type="text"
        placeholder="Ex: Thien Toan"
        autofocus
        minlength="2"
        maxlength="50"
        class="feedback-form__name"
        :error="nameError"
      />
    </div>

    <div class="mt-4">
      <AppInput
        v-model="email"
        name="email"
        label="Email"
        type="email"
        placeholder="Ex: example@gmail.com"
        :required="true"
        class="feedback-form__email"
        :error="emailError"
      />
    </div>

    <div class="mt-4">
      <AppInput
        v-model="content"
        name="content"
        label="Content"
        placeholder="5 - 2000 characters"
        :required="true"
        minlength="5"
        maxlength="2000"
        class="feedback-form__content"
        :error="contentError"
      />
    </div>

    <div class="mt-4 text-center">
      <button type="submit" :class="['btn btn--primary', { 'btn--disabled': !meta.valid }]" :disabled="!meta.valid">
        <i class="fas fa-paper-plane" />
        Send feedback
      </button>
    </div>
  </form>
</template>

<script setup lang="ts">
import { inject } from 'vue'
import { useForm, useField } from 'vee-validate'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import * as yup from 'yup'
import AppInput from '&/AppInput.vue'
import apiCall from '%/plugins/axios'

const GStore = inject('GStore')
const router = useRouter()
const i18n = useI18n()
const schema = yup.object({
  name: yup.string().max(50),
  email: yup.string().required().email(),
  content: yup.string().required().min(5).max(2000)
})

const { meta, handleSubmit } = useForm({ validationSchema: schema })

const { value: name, errorMessage: nameError } = useField('name')
const { value: email, errorMessage: emailError } = useField('email')
const { value: content, errorMessage: contentError } = useField('content')

const submit = handleSubmit((values) => {
  createFeedback({ ...values })
    .then(() => {
      router.push({ name: 'CategoryIndex' })

      GStore.flashMessage.content = i18n.t('feedback.successMessage')
      setTimeout(() => {
        GStore.flashMessage.content = null
        GStore.flashMessage.type = 'notice'
      }, 10000)
    })
    .catch((err) => console.error(err))
})

const createFeedback = function (feedback) {
  return new Promise((resolve, reject) => {
    apiCall
      .post('/feedback', { feedback })
      .then(() => {
        resolve(true)
      })
      .catch((err) => reject(err))
  })
}
</script>

<style lang="sass" scoped></style>
