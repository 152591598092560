import { defineStore } from "pinia";
import apiCall from '%/plugins/axios'

export const useArticleStore = defineStore('articleStore', {
    state: () => {
        return {
            dataLoading: false,
            articles: [],
            article: null,
        }
    },

    actions: {
        fetchArticles() {
            return new Promise((resolve, reject) => {
                apiCall
                    .get('/articles')
                    .then((res) => {
                        this.articles = res.data
                        resolve(true)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchArticle(slug) {
            return new Promise((resolve, reject) => {
                apiCall
                    .get(`/articles/${slug}`)
                    .then((res) => {
                        this.article = res.data
                        resolve(true)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
    }
})
