import { defineStore } from "pinia";
import apiCall from '%/plugins/axios'

export const useWordStore = defineStore('wordStore', {
    state: () => {
        return {
            dataLoading: false,
            page: {
                currentPage: 1,
                totalPage: 1,
                totalCount: null
            },
            categories: [],
            category: null,
            words: [],
            word: null,
            searchedWords: [],
        }
    },

    actions: {
        fetchCategories() {
            return new Promise((resolve, reject) => {
                apiCall
                    .get('/categories')
                    .then((res) => {
                        this.categories = res.data
                        resolve(true)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchCategory(slug) {
            return new Promise((resolve, reject) => {
                apiCall
                    .get(`/categories/${slug}?page=${this.page.currentPage}`)
                    .then((res) => {
                        this.category = res.data
                        this.page.totalPage = parseInt(res.headers['total-pages'])
                        this.page.totalCount = parseInt(res.headers['total-count'])
                        resolve(true)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchWords() {
            return new Promise((resolve, reject) => {
                apiCall
                    .get('/words')
                    .then((res) => {
                        this.words = res.data
                        resolve(true)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchWord(category_slug, ja) {
            return new Promise((resolve, reject) => {
                apiCall
                    .get(`/words/${category_slug}/${ja}`)
                    .then(res => {
                        this.word = res.data
                        resolve(true)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        search(keyword) {
            return new Promise((resolve, reject) => {
                apiCall
                    .get(`/search/${keyword}?page=${this.page.currentPage}`)
                    .then((res) => {
                        this.searchedWords = res.data
                        this.page.totalPage = parseInt(res.headers['total-pages'])
                        this.page.totalCount = parseInt(res.headers['total-count'])
                        resolve(true)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
    },

    getters: {
        categoryCount: (state) => state.categories.length,
        wordCount: (state) => state.words.length,
    }
})
