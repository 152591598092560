<template>
  <div class="grid grid-cols-12 gap-8">
    <main class="col-span-12 lg:col-span-9">
      <div id="mainContent">
        <SearchForm class="bg-white p-2 sticky top-0 z-40 shadow-md block lg:hidden" />
        <router-view :key="$route.fullPath" />
      </div>
      <PleaseDisableAdblock id="pleaseDisableAdblock" class="hidden" />
    </main>

    <aside class="hidden lg:block lg:col-span-3">
      <AppAside />
    </aside>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, onMounted } from 'vue'
import { useWordStore } from '%/stores/wordStore'
import SearchForm from '&/SearchForm.vue'
import AppAside from '&/AppAside.vue'
import PleaseDisableAdblock from '&/PleaseDisableAdblock.vue'

const wordStore = useWordStore()

onBeforeMount(() => (wordStore.dataLoading = true))

onMounted(() => {
  wordStore.fetchCategories()
  wordStore.fetchWords().finally(() => (wordStore.dataLoading = false))
})
</script>
