<template>
  <div class="my-4">
    <AdsenseMain1 />
  </div>

  <AppLoading v-if="loading" />

  <div
    v-else
    class="border-2 border-solid border-stone-200 rounded-lg w-[96%] lg:w-[75%] mx-auto p-4 bg-white shadow-md"
  >
    <WordDetail :word="wordStore.word" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useWordStore } from '%/stores/wordStore'
import WordDetail from '&/WordDetail.vue'
import AdsenseMain1 from '&/AdsenseMain1.vue'

const props = defineProps<{
  category_slug: string
  word: string
}>()

const wordStore = useWordStore()
const loading = ref(true)

onMounted(() => {
  window.scrollTo(0, 0)

  wordStore
    .fetchWord(props.category_slug, props.word)
    .then(() => {
      document.title = `Nghĩa của từ ${wordStore.word.ja} | Tiếng Nhật chuyên ngành IT`
      document.querySelector('meta[name="description"]').content =
        `Học tiếng Nhật công nghệ thông tin. Tổng hợp từ vựng tiếng Nhật chuyên ngành IT. Tiếng Nhật chuyên ngành IT thường dùng trong công việc. | Học tiếng Nhật IT online`
    })
    .finally(() => (loading.value = false))
})
</script>
